<template>
  <div class="my-collect-container">
    <product-collect-item></product-collect-item>
  </div>
</template>

<script>
import ProductCollectItem from './product-collect-item.vue'
export default {
  name:"MyCollect",
  components:{
    ProductCollectItem
  }
}
</script>

<style lang="less" scoped>
.my-collect-container{
    background: #FFFFFF;
    margin-top: 1px;
    padding-bottom: 30px;
    border-radius: 0px 0px 8px 8px;
}
</style>